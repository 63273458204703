<template>
  <div>
    <div class="container" style="min-height: 150px">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-12">
              <h1><b-icon icon="grid3x3" /> Criterios de Calificación</h1>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="container">
            <b-row>
              <b-col><RatingCriteriaRegister /></b-col>
            </b-row>
          </div>
          <div class="container col-12">
            <div>
              <b-table
                striped
                responsive
                stacked="lg"
                :items="desserts"
                :fields="fields"
              >
                <template #cell(index)="row">
                  {{ row.index + 1 }}
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RatingCriteriaRegister from "./RatingCriteria-register.vue";
export default {
  components: { RatingCriteriaRegister },
  data: () => ({
    fields: [
      { key: "index", label: "#" },
      { key: "name", label: "Nombre del criterio de calificación" },
    ],
    desserts: [],
  }),

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      await this.$axios
        .get("/configuration/academic-record/qualification-criteria", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.desserts = res.data;
        })
        .catch((e) => {
          console.log(e);
          this.$errorQuery(e);
        });
    },
  },
};
</script>
<style>
.card-header {
  background-color: rgb(119, 35, 45);
  color: white;
}
.modal-content {
  background-color: rgb(243, 243, 243);
}
</style>