<template>
  <div id="RatingCriteriaRegister">
    <button
      @click="add()"
      style="background-color: #1d425e; color: white"
      class="btn mb-3"
    >
      Añadir Criterio de calificación
    </button>
    <div>
      <b-modal scrollable centered v-model="show" title="Añadir Criterio">
        <b-container fluid>
          <!--Contenido modal-->
          <form>
            <AlertError ref="error" />
            <div class="form-group">
              <label for="validationServer01">Nombre</label>
              <b-form-input
                type="text"
                class="form-control"
                placeholder="Ingrese nombre"
                v-model="name"
              />
            </div>
          </form>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="saveAddItem()"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Enviar
            </button>
            <button
              type="button"
              @click="exit()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import AlertError from "../../components/AlertError.vue";
export default {
  name: "RatingCriteriaRegister",
  components: { AlertError },
  data() {
    return {
      show: false,
      name: "",
    };
  },
  methods: {
    exit() {
      this.show = false;
    },
    add() {
      this.name = "";
      this.show = true;
    },
    async saveAddItem() {
      await this.$axios
        .post(
          "/configuration/academic-record/qualification-criteria-register",
          { name: this.name },
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((res) => {
          this.show = false;
          this.$message.success(res.data.content);
          this.$parent.initialize();
        })
        .catch((e) => {
          console.log(e);
          this.$refs.error.showAlert(e.response.data.content);
        });
    },
  },
};
</script>
<style>
.modal-content {
  background-color: rgb(243, 243, 243);
}
</style>